import { HintContext } from "../../pages/QuizPage";
const MailContent10 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content10">
          <img
            id="inpostLogo"
            src="https://ci3.googleusercontent.com/proxy/LAzrec2oWyNVAgocEXxPvlWcdekVAbme906zUjOdz4i-1nm63lonPZ3uAbwAm28CXdR1-lo6tB3NR0Tnxfuxxu1LjBg=s0-d-e1-ft#https://static.inpost.pl/images/mailing2/logo.png"
            alt="InPost - Out of the Box"
            width="123"
            height="75"
            border="0"
          />

          <img src="./inpost-graphics.png" alt="InPost" className="face" />
          <p>Pora na Twój ruch!</p>
          <p>
            Paczka od <b>INFINITE</b> czeka na Ciebie w skrytce.
          </p>
          <p>
            Jeśli nie odbierzesz paczki w wyznaczonym czasie, zostanie ona
            zwrócona do Nadawcy – a tego byśmy nie chcieli!
          </p>
          <p>Widzimy się?</p>
          <div className="divider" />
          <p>
            <b>Twój paczkomat:</b>
          </p>
          <p>
            WAR01N
            <br />
            Warszawa, Dworcowa 12, sklep Żabka
          </p>

          <a
            className="inpostButton"
            href="https://www.google.com/maps?q=53.20869,17.79977&z=15&key=AIZmfMs"
          >
            Pokaż na mapie
          </a>
          <div className="divider" />
          <p>
            <b>Czas na odbiór:</b>
            <br />
            05/07/2023 11:00
            <br />
            <p className="inpostYellowText">Haloo, Haloo, Ja tu czekam!</p>
          </p>
          <div className="divider" />
          <p>
            Aby odebrać paczkę, zeskanuj kod QR, przykładając go do czytnika:
          </p>
          <img
            id="qr"
            alt="kod QR"
            width="200"
            height="200"
            src="https://mail.google.com/mail/u/0?ui=2&amp;ik=499cb3b40d&amp;attid=0.0.1&amp;permmsgid=msg-f:1770389355254453862&amp;th=1891afbd1a131666&amp;view=fimg&amp;fur=ip&amp;sz=s0-l75-ft&amp;attbid=ANGjdJ9DB8LSrqHy2wKKXVj89K4TUWIwnqNhGqa3K5kAooHGMZoMspQmDGf2GH2zcc_mmdHclIXaxg2AH9SYGIhjF1-pTWBp6k8VrRm5RgsuF4UeAllg4zNDYVgMEnI&amp;disp=emb&amp;realattid=fb233a74-7fd1-3c80-94c0-a195a0703a9a"
          />
          <p>lub wpisz poniższe dane:</p>
          <div className="codeData">
            Numer telefonu
            <br />
            <span className="inpostYellowText">690376574</span>
            <br />
            <br />
            Kod odbioru
            <br />
            <span className="inpostYellowText">526328</span>
          </div>
          <p>
            <b>Numer paczki:</b>
            <br />
            696320328386014019195563
          </p>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent10;
