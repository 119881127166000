import "../styles/progress.css";

const ProgressBar = ({ activeQuestion, questions }) => {
  return (
    <div class="container">
      <div class="progress">
      </div>
      <div class="steps">
        {questions?.map((question, index) => (
          <div
            class={index <= activeQuestion ? "step completed" : "step"}
            id={index}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
