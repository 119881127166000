import { HintContext } from "../../pages/QuizPage";
const MailContent2 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content2">
          <img
            id="allegroLogo"
            src="./allegro-logo.png"
            alt="allegro-logo"
          ></img>
          <img
            id="allegroBanner"
            src="./allegro-banner.png"
            alt="allegro-banner"
          ></img>
          <p class="left">
            W związku z obecną sytuacją w kraju i apelami o pozostanie w domach
            i unikanie dużych skupisk ludności wprowadzamy specjalne ułatwienia:
          </p>
          <ul class="left">
            <li>
              Do 18 kwietnia wszyscy klienci będą mogli bezpłatnie aktywować
              dostawy Allegro Smart! na miesiąc
            </li>
            <li>
              Po zakończeniu darmowego okresu usługa wygaśnie automatycznie, bez
              pobierania jakichkolwiek opłat
            </li>
          </ul>
          <p class="left">
            <strong>Jak aktywować Allegro Smart!:</strong>
          </p>
          <ol class="left">
            <li>
              Przejdź na stronę{" "}
              <a
                className={hint ? "redText" : ""}
                href="https://drive.google.com.download-photo.sytez.net/AONh2e0hVP"
              >
                Allegro SMART
              </a>
            </li>
            <li>Zakcetuj regulami n i aktywuj usługę</li>
            <li>Korzystaj z miesiąca darmowych dostaw</li>
          </ol>
          <a href="https://drive.google.com.download-photo.sytez.net/AONh2e0hVP">
            <button>AKTYWUJ</button>
          </a>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent2;
