const NavBar = ({ children }) => {
  return (
    <header>
      <div className="wrap">
        <a
          href={
            process.env.REACT_APP_CYBER == "true"
              ? "https://www.cyber3.pl/"
              : "https://uniqsoft.pl"
          }
          target="_blank"
          className="logo"
          rel="noreferrer"
        >
          <img
            src={
              process.env.REACT_APP_CYBER == "true"
                ? "./logo-cyber.svg"
                : "./logo.svg"
            }
            alt=""
          />
        </a>
        {children}
      </div>
    </header>
  );
};

export default NavBar;
