import { HintContext } from "../../pages/QuizPage";
import Attachment from "./Attachment";
const MailContent8 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content8">
          <p>Szanowna Pani, Szanowny Panie,</p>
          <p>dziękujemy za zakup biletu. Oto podsumowanie zamówienia:</p>
          <table>
            <tbody>
              <tr>
                <th>Lp.</th>
                <th>Ważność</th>
                <th>Relacja</th>
                <th>Numer biletu</th>
                <th>Status biletu</th>
                <th>Usługa</th>
                <th>Nr pociągu</th>
                <th>Rodzaj biletu</th>
                <th>Operacja</th>
                <th>Cena</th>
              </tr>
              <tr>
                <td rowspan="1">1</td>
                <td>
                  od: 2023-04-28
                  <br />
                  godz: 14:18
                </td>
                <td>
                  POZNAN GL <br />
                  WROCLAW GL
                </td>
                <td>
                  <b>168039212</b>
                </td>
                <td>Opłacony</td>
                <td>Miejsce do siedzenia</td>
                <td>IC 3514</td>
                <td>Krajowy</td>
                <td>
                  <a
                    href="https://bilet.intercity.pl/konto_gosc_logowanie.jsp?bsk_mail_ticket=show&amp;bil_id=168039212"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zarządzaj biletem
                  </a>
                </td>
                <td>22,54 zł</td>
              </tr>
              <tr>
                <td colspan="9" align="right">
                  SUMA
                </td>
                <td>
                  <b>22,54 zł</b>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Prosimy o zapisanie załączonego pliku <b>pdf z biletem</b> w
            urządzeniu mobilnym lub wydruk, w celu okazania do kontroli w
            pociągu.
          </p>
          <p>
            Uprzejmie prosimy o zapoznanie się z ważnymi informacjami na temat
            podróżowania z PKP Intercity podczas epidemii.{" "}
            <a
              href="https://www.intercity.pl/pl/jak-podrozowac-z-pkp-intercity-podczas-epidemii-poradnik-pasazera.html?utm_source=email-z-biletem-pl&amp;utm_medium=email&amp;utm_campaign=poradnik-pasazera-covid-19"
              target="_blank"
              rel="noreferrer"
            >
              Sprawdź poradnik
            </a>
          </p>
          <p>
            Zwrotu należności za niewykorzystany bilet dokonuje się na podstawie
            wniosku złożonego przez płatnika w wyznaczonych przez PKP Intercity
            S.A. punktach, z wyjątkiem należności zwracanych automatycznie na
            zasadach określonych w Regulaminie e-IC.
          </p>
          <p>
            Życzymy przyjemnej podróży <br />
            Zespół PKP Intercity
          </p>
          <div class="mailFooter">
            <Attachment filename="bilet_168039212.pdf" />
          </div>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent8;
