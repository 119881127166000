import { useState } from "react";

const Mail = ({ question, result }) => {
  var modal = document.getElementById("myModal");
  var modalButton = document.getElementById("myModalButton");
  var modalContent = document.getElementById("myModalContent");
  window.onclick = function (event) {
    if (event.target !== modal && event.target !== modalButton) {
      setModalVisible(false);
    }
  };

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className="box mail">
      <div className="mailInfo">
        <div className="avatarImage">
          <p>{question?.senderName[0]}</p>
        </div>
        <div>
          <div className="senderData">
            <p>{question.senderName}</p>
            {question.sender()}
          </div>
          do mnie{" "}
          <button
            id="myModalButton"
            onClick={(e) => {
              setModalVisible(true);
              if (modalContent) {
                modalContent.style.position = "absolute";
                modalContent.style.top = e.clientY;
                modalContent.style.left = e.clientX;
              }
            }}
          >
            więcej
          </button>
          <div
            id="myModal"
            className={modalVisible ? "modal visible" : "modal"}
          >
            <div id="myModalContent" className="modalContent">
              <div className="modalRow">
                <p className="rowLabel">od:</p>
                <p className="rowValue">
                  {question.senderName} {`<${question.sender}>`}
                </p>
              </div>
              <div className="modalRow">
                <p className="rowLabel">do:</p>
                <p className="rowValue">{result}</p>
              </div>
              <div className="modalRow">
                <p className="rowLabel">data:</p>
                <p className="rowValue">10 paź 2023, 11:32:52</p>
              </div>
              <div className="modalRow">
                <p className="rowLabel">temat:</p>
                <p className="rowValue">{question.title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mailContent">{question.mail()}</div>
    </div>
  );
};

export default Mail;
