import { HintContext } from "../../pages/QuizPage";

const MailContent5 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content5">
          <div className="infoBox">
            <p>12.10.2023 11:34 przyszedł do Ciebie nowy faks (1 strona)</p>
            <a
              className={hint ? "redText docName" : "docName"}
              href="http://efax.hosting.com.mailru382.co/efaxdelivery/2017Dk4h325RE3"
            >
              <p>Kliknij, aby zobaczyć</p>
            </a>
          </div>
          <div className="footer">
            <img src="./efax-logo.png" alt="eFax" class="logo"></img>
            Dziękujemy za skorzystanie z usługi eFax. Jeśli masz jakieś pytania
            lub uważasz, że ten faks dotarł do Ciebie przez pomyłkę, wejdź na
            stronę https://www.efax.co.uk/lp/wirtualny-fax
          </div>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent5;
