import { HintContext } from "../../pages/QuizPage";

const Address5 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <p className="grayText">
          noreply@
          <span className={hint ? "redText" : "grayText"}>efacks.com</span>
        </p>
      )}
    </HintContext.Consumer>
  );
};

export default Address5;
