import { HintContext } from "../../pages/QuizPage";
const MailContent9 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content9">
          <div className="dropBoxIcon"></div>
          <div>
            <p>Cześć,</p>
            <p>
              Twój Dropbox zbliża się do limitu miejsca. Po jego przekroczeniu
              nie będzie można dodawać nowych pozycji ani synchronizować zmian
              na wielu urządzeniach.
            </p>
            <p>
              Uzyskaj nawet 3,000 GB miejsca i inne wspaniałe funkcje, takie jak
              180-dniowa ochrona przed wypadkami, po zmianie taryfy na Dropbox
              premium.
            </p>
            <a href="https://www.dropbox.com/buy" class="dropboxButton">
              Next step
            </a>
          </div>
          <div className="dropBoxFooter">
            <p>
              Nie chcesz już otrzymywać porad od Dropbox?{" "}
              <a href="https://www.dropbox.com/pref2/e/112113543884/14572153/174/AACCOKBjHmIgf6VWDeI">
                Anuluj subskrypcję
              </a>
              <br />
              Dropbox, Inc., PO Box 77767, San Francisco, CA 94107
            </p>
          </div>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent9;
