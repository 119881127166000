import { HintContext } from "../../pages/QuizPage";

const Address1 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <p className="grayText">
          info@
          <span className={hint ? "redText" : "grayText"}>regulamin-poczty.email</span>
        </p>
      )}
    </HintContext.Consumer>
  );
};

export default Address1;
