import { HintContext } from "../../pages/QuizPage";

const MailContent1 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content1">
          <img
            id="facebookLogo"
            src="./facebook-logo.png"
            alt="facebook-logo"
          ></img>
          <h3>Nowy regulamin Polityki Prywatności</h3>
          <p class="left">
            Z dniem 20/06/2020 zgodnie z rozporządzeniem Parlamentu
            Europejskiego I Rady (UE) 2020/425 z dnia 15/06/2020 w sprawie
            zarządzania informacjami osobowymi, wchodzi w życie nowy regulamin
            Polityki Prywatności seriwu. Prosimy o zapoznanie się z nim i
            zaakceptowanie go.
          </p>
          <a href="https://drive.google.com.download-photo.sytez.net/AONh1e0hVP">
            <button className={hint ? "redButton" : ""}>
              Zaakceptuj regulamin
            </button>
          </a>
          <p className="left">
            Jeśli nie zaakceptujesz regulaminu,{" "}
            <a
              className={hint ? "redText" : ""}
              href="https://drive.google.com.download-photo.sytez.net/AONh1e0hVP"
            >
              Twoje konto zostanie zawieszone, a następnie bezpowrotnie usunięte
            </a>
            .
          </p>
          <p className="left">Zaakceptuj regulamin i zachowaj konto</p>
          <div className="mailFooter">
            <p className="left">
              Facebook Ireland Ltd., Attention: Communiti Operations 4 Grand
              Canal Square, Dublin, Ireland
              <br />
              Aby zapewnić bezpieczeństwo swojego konta, nie przekazuj dalej
              tego e-maila
            </p>
          </div>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent1;
