import { HintContext } from "../../pages/QuizPage";
const MailContent6 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content6">
          <p>
            Cześć, możesz zweryfikować{" "}
            <a
              id={hint ? "redDocName" : ""}
              className="docName"
              href="https://drive.google.com.download-photo.modelo.net/AONh1e0hVP"
            >
              TE MATERIAŁY
            </a>
            ?
          </p>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent6;
