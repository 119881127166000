import Mail from "./mails/Mail";

const Question = ({
  question,
  answer,
  onAnswerSelected,
  index,
  nextQuestion,
  result,
}) => {
  return (
    <div>
      <h2>
        {answer === "answering"
          ? question.question
          : answer === true
          ? "Dobrze!"
          : "Źle!"}
      </h2>
      {process.env.REACT_APP_NO_HINT == "false" && (
        <p className="large center">
          {answer !== "answering"
            ? question.correctAnswer == "phishing"
              ? "To e-mail mający na celu wyłudzenie informacji"
              : "To prawdziwy e-mail"
            : question.description}
        </p>
      )}
      {answer !== "answering" && process.env.REACT_APP_NO_HINT == "false" && (
        <p className="center">
          {answer === true
            ? question.explanationCorrect
            : question.explanationIncorrect}
        </p>
      )}
      {answer === "answering" ? (
        <div className="button-container">
          <button
            className="button fixed red"
            onClick={() => onAnswerSelected("phishing", index)}
          >
            <span>Phishing</span>
          </button>
          <button
            className="button fixed green"
            onClick={() => onAnswerSelected("non-phishing", index)}
          >
            <span>Prawdziwa wiadomość</span>
          </button>
        </div>
      ) : (
        <div className="button-container">
          <button className="button" onClick={nextQuestion}>
            <span>Dalej</span>
          </button>
        </div>
      )}

      <Mail question={question} result={result.username} />
    </div>
  );
};

export default Question;
