const MailContent7 = () => {
  return (
    <div id="content7">
      <div className="header">
        <img
          id="microsoftLogo"
          src="./microsoft-log.jpg"
          alt="microsoft-logo"
        ></img>
        <a
          className="docName"
          href="https://www.microsoft.com/pl-pl/microsoft-365/onedrive/online-cloud-storage"
        >
          OneDrive
        </a>
      </div>
      <div className="grayBox">
        <img id="warning" src="./warning.png" alt="warning"></img>
        Twój dysk OneDrive jest prawie pełny
      </div>
      <p class="center">
        Twoja przestrzeń dyskowa OneDrive jest prawie pełna. Gdy przestrzeń
        dyskowa OneDrive będzie pełna, dokumenty, zdjęcia i zmiany nie będą
        synchronizowane z OneDrive.com ani z Twoimi urządzeniami. Nie można też
        będzie dodawać nowych dokumentów i zdjęć.
      </p>
      <p class="center">
        Aby nadal korzystać z OneDrive,{" "}
        <a href="https://click.mail.onedrive.com/?qs=41032f6c1301c0d">
          uaktualnij przestrzeń dyskową
        </a>{" "}
        lub{" "}
        <a href="https://click.mail.onedrive.com/?qs=419040de3d80acb7">
          usunąć pliki
        </a>
        , których już nie używasz.
      </p>
      <a href="https://click.mail.onedrive.com/?qs=41032f6c1301c0d">
        <button id="microsoftButton">
          Uzyskaj więcej przestrzeni dyskowej
        </button>
      </a>
    </div>
  );
};

export default MailContent7;
