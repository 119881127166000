import NavBar from "../components/NavBar";

const LandingPage = () => {
  return (
    <div className="layout">
      <NavBar />
      <div className="wrap">
        <div className="row">
          <div className="col col-auto">
            <h2 className="left">
              Czy potrafisz rozpoznać phishingową wiadomość?
            </h2>
            <p className="large">
              Nie bez przyczyny słowo „phishing” brzmi niemal tak samo jak
              „fishing”, które z angielskiego oznacza łowienie ryb.
            </p>
            <p>
              Przynętą może być np. informacja o wygranej w loterii - przestępcy
              nakłaniają ofiarę, aby podała dane swojego rachunku bankowego, na
              które rzekomo przeleją pieniądze.
            </p>
            <p>W rzeczywistości włamują się na jej konto.</p>
            <div className="button-container">
              <a href="/quiz" className="button">
                <span>Rozpocznij</span>
              </a>
            </div>
          </div>
          <div className="col">
            <img src={process.env.REACT_APP_CYBER == "true" ? "./img1-cyber.png" :"./img1.png"} alt="" className="image" />
          </div>
        </div>
      </div>
      <footer>
        <div className="wrap">
          Wszystkie nazwy handlowe, nazwy produktów, nazwy firm i ich loga użyte
          w niniejszych testach należą do ich właścicieli i użyte są wyłącznie w
          celach informacyjnych.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
