import { HintContext } from "../../pages/QuizPage";

const Address10 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => <p className="grayText">info@paczkomaty.pl</p>}
    </HintContext.Consumer>
  );
};

export default Address10;
