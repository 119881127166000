import { HintContext } from "../../pages/QuizPage";

const Address8 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => <p className="grayText">bilet.eic@intercity.pl</p>}
    </HintContext.Consumer>
  );
};

export default Address8;
