import Attachment from "./Attachment";

const MailContent3 = () => {
  return (
    <div id="content3">
      <p>Dzień Dobry,</p>
      <p>W załączniku przesyłamy potwierdzenie operacji na stronie bankowości elektronicznych iPKO.</p>
      <p>Z poważaniem, <br/>Zespół PKO Banku Polskiego</p>
      <p>Ten e-mail został wygenerowany automatycznie.</p>
      <div class="mailFooter">
      <Attachment/></div>
    </div>
  );
};

export default MailContent3;
