import { HintContext } from "../../pages/QuizPage";

const MailContent4 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <div id="content4">
          <p>Maria Konewka udostępnił(a) link do tego dokumentu:</p>
          <div className="textFlex">
            <div className="docImage"></div>
            <div className="docName">
              <a
                id={hint ? "redDocName" : ""}
                className={hint ? "redText" : "grayText"}
                href="http://drive--google.com/maria.konewka"
              >
                <p>Budżet działu na rok 2023.doc</p>
              </a>
            </div>
            <div className="mailFooter textFlex margin20">
              <div className="userIcon"></div>
              <p class="footerText">
                Cześć. To ten dokument, o który Ci chodziło. Odezwij się, jeśli
                jeszcze czegoś potrzebujesz.
              </p>
            </div>
          </div>
          <a href="http://drive--google.com/maria.konewka">
            <button className="googleDocsButton">Otwórz w Dokumentach</button>
          </a>
        </div>
      )}
    </HintContext.Consumer>
  );
};

export default MailContent4;
