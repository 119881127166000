import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Question from "../components/Question";
import UserDataForm from "../components/UserDataForm";
import { quiz } from "../const/quiz";
import "../styles/style.scss";
import "../styles/test.css";
import ProgressBar from "../components/ProgressBar";

export const HintContext = React.createContext({ hint: false });
const QuizPage = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [showHints, setShowHints] = useState(false);
  const [result, setResult] = useState({
    username: null,
    mail: null,
    // username: "username",
    // mail: "mail@wp.pl",
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  useEffect(() => {
    if (result?.username && !activeQuestion) {
      setActiveQuestion(0);
    }
  }, [result?.username, activeQuestion]);

  const { questions } = quiz;
  const question = activeQuestion !== null ? questions?.[activeQuestion] : null;

  const setUsernameAndMail = (username, mail) => {
    setResult((prev) => {
      return { ...prev, username: username, mail: mail };
    });
  };

  const toggleHints = (show) => {
      setShowHints(show);
  };

  const onAnswerSelected = (answer, index) => {
    toggleHints(true);
    if (answer === question?.correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const nextQuestion = () => {
    toggleHints(false);
    setResult((prev) =>
      selectedAnswer
        ? {
            ...prev,
            score: prev.score + quiz.perQuestionScore,
            correctAnswers: prev.correctAnswers + 1,
          }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    );
    if (activeQuestion < quiz?.questions?.length - 1) {
      setActiveQuestion(activeQuestion + 1);
      setSelectedAnswer("");
    } else {
      setActiveQuestion(null);
      setShowResult(true);
    }
  };

  return (
    <HintContext.Provider value={showHints}>
      <div className="layout">
        <NavBar>
          {question && !showResult &&  (
            <>
            {/* <div className="title">
              Pytanie {activeQuestion + 1} z {quiz?.questions?.length}
            </div> */}
            <ProgressBar activeQuestion={activeQuestion} questions={questions}/>
            </>
          )}
        </NavBar>
        {!showResult ? (
          <div className="wrap">
            <div>
              {!result.username && (
                <UserDataForm setData={setUsernameAndMail} />
              )}
              {question && (
                <Question
                  onAnswerSelected={onAnswerSelected}
                  question={question}
                  answer={selectedAnswer === "" ? "answering" : selectedAnswer}
                  index={activeQuestion}
                  nextQuestion={nextQuestion}
                  result={result}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="wrap">
            <div className="row">
              <div className="col col-auto">
                <h2 className="left">Wyniki</h2>
                <p className="large">
                  Udało Ci się odpowiednio sklasyfikować {result.correctAnswers}{" "}
                  z {quiz?.questions?.length} wiadomości.
                </p>
                <p>
                  {result.correctAnswers / quiz?.questions?.length > 0.9
                    ? "Gratulacje! Jesteś odpowiedzialnym użytkownikiem."
                    : "Powinieneś jeszcze poćwiczyć."}
                </p>
                <div className="button-container">
                  <a href="/quiz" className="button">
                    <span>Zacznij od początku</span>
                  </a>
                </div>
              </div>
              <div className="col col-fixed">
                <img
                  src={
                    result.correctAnswers / quiz?.questions?.length > 0.9
                      ? process.env.REACT_APP_CYBER == "true"
                        ? "./img3-cyber.png"
                        : "./img3.png"
                      : process.env.REACT_APP_CYBER == "true"
                      ? "./img2-cyber.png"
                      : "./img2.png"
                  }
                  alt=""
                  className="image no-margin"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </HintContext.Provider>
  );
};

export default QuizPage;
