import { HintContext } from "../../pages/QuizPage";

const Address9 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => <p className="grayText">no-reply@em-s.dropbox.com</p>}
    </HintContext.Consumer>
  );
};

export default Address9;
