import { useState } from "react";

const UserDataForm = ({ setData }) => {
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formProps = Object.fromEntries(data);
    if (!formProps?.username?.length > 0) {
      setUsernameError(true);
      return;
    }
    setUsernameError(false);
    if (!formProps?.mail?.length > 0) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    if (!validateEmail(formProps?.mail)) {
      setEmailValidationError(true);
      return;
    }
    setEmailValidationError(false);

    setData(formProps?.username, formProps?.mail);
  };

  return (
    <div className="box">
      <form onSubmit={onSubmit}>
        <div className="form_row">
          <label for="username">Nazwa użytkownika</label>
          <input type="text" id="username" name="username" />
          {usernameError && <div className="input_error">Pole wymagane</div>}
        </div>
        <div className="form_row">
          <label for="mail">Adres e-mail</label>
          <input type="text" id="mail" name="mail" />
          {emailError && <div className="input_error">Pole wymagane</div>}
          {emailValidationError && (
            <div className="input_error">Nieprawidłowy adres e-mail</div>
          )}
        </div>
        <div className="button-container">
          <button type="submit" className="button"><span>Dalej</span></button>
        </div>
      </form>
    </div>
  );
};

export default UserDataForm;
