import { HintContext } from "../../pages/QuizPage";

const Address2 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <p className="grayText">
          admin@allegro
          <span className={hint ? "redText" : "grayText"}>4</span>.pl
        </p>
      )}
    </HintContext.Consumer>
  );
};

export default Address2;
