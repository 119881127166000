import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import QuizPage from "./pages/QuizPage";
const StylesCyber = lazy(() => import("./pages/stylesCyber"));
const StylesUniqsoft = lazy(() => import("./pages/styles"));

const ThemeSelector = ({ children }) => {
  return (
    <>
      <Suspense fallback={null}>
        {process.env.REACT_APP_CYBER == "true" ? (
          <StylesCyber />
        ) : (
          <StylesUniqsoft />
        )}
      </Suspense>
      {children}
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/quiz",
    element: <QuizPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeSelector>
      <RouterProvider router={router} />
    </ThemeSelector>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
