import { HintContext } from "../../pages/QuizPage";

const Address6 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => <p className="grayText">anna@techcom-office.pl</p>}
    </HintContext.Consumer>
  );
};

export default Address6;
