import Address1 from "../components/mails/Address1";
import Address10 from "../components/mails/Address10";
import Address2 from "../components/mails/Address2";
import Address3 from "../components/mails/Address3";
import Address4 from "../components/mails/Address4";
import Address5 from "../components/mails/Address5";
import Address6 from "../components/mails/Address6";
import Address7 from "../components/mails/Address7";
import Address8 from "../components/mails/Address8";
import Address9 from "../components/mails/Address9";
import MailContent1 from "../components/mails/MailContent1";
import MailContent10 from "../components/mails/MailContent10";
import MailContent2 from "../components/mails/MailContent2";
import MailContent3 from "../components/mails/MailContent3";
import MailContent4 from "../components/mails/MailContent4";
import MailContent5 from "../components/mails/MailContent5";
import MailContent6 from "../components/mails/MailContent6";
import MailContent7 from "../components/mails/MailContent7";
import MailContent8 from "../components/mails/MailContent8";
import MailContent9 from "../components/mails/MailContent9";

export const quiz = {
  perQuestionScore: 1,
  questions: [
    {
      question: "Facebook ma nowy regulamin",
      description:
        "Pamiętaj, by sprawdzać adresy URL linków, najeżdżając na nie wskaźnikiem myszy lub przytrzymując je, a także sprawdzać adresy e-mail. Nie martw się, żadne z tych linków nie działają - nie mamy zamiaru przekierować Cię w niebezpieczne miejsca.",
      title: "mail title",
      senderName: "Facebook",
      sender: Address1,
      mail: MailContent1,
      correctAnswer: "phishing",
      explanationCorrect:
        "Dobrze! Twoje podejrzenia wzbudził pewnie podejrzany adres e-mail nadawcy. Powinien być to adres o domenie facebook.com. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
      explanationIncorrect:
        "Zwróć uwagę na podejrzany adres e-mail nadawcy. Powinien być to adres o domenie facebook.com. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
    },
    {
      question: "Twój dysk jest pełen",
      description:
        "Pora przejść na wyższy pakiet lub usunąć kilka niepotrzebnych plików",
      title: "Twój dysk OneDrive jest prawie pełny",
      senderName: "Microsoft OneDrive",
      sender: Address7,
      mail: MailContent7,
      correctAnswer: "non-phishing",
      explanationCorrect:
        "To prawdziwy komunikat z usługi OneDrive. Nadawcą jest „mail.onedrive.com” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „onedrive.com”",
      explanationIncorrect:
        "Nie każdy mail jest próbą oszustwa. To prawdziwy komunikat z usługi OneDrive. Nadawcą jest „mail.onedrive.com” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „onedrive.com”",
    },
    {
      question: "Przyszedł nowy faks.",
      description: "Praca goni, ale znajdź chwilę na jego weryfikację.",
      title: "Jak aktywować Allegro Smart 23486244",
      senderName: "Wiadomość faks NoReply [administrator]",
      sender: Address5,
      mail: MailContent5,
      correctAnswer: "phishing",
      explanationCorrect:
        "Brawo za spostrzegawczość! Domena adresu e-mail nadawcy jest zapisana błędnie jako „efacks”. Link w rzeczywistości kieruje w inne miejsce. Wyłudzanie informacji często polega na tym, że ktoś próbuje oszukać Cię przy użyciu adresów URL, które imitują inne.",
      explanationIncorrect:
        "Domena adresu e-mail nadawcy jest zapisana błędnie jako „efacks”. Link w rzeczywistości kieruje w inne miejsce. Wyłudzanie informacji często polega na tym, że ktoś próbuje oszukać Cię przy użyciu adresów URL, które imitują inne.",
    },

    {
      question: "Twoj bilet na pociąg znajduje się w skrzynce odbiorczej",
      description:
        "Często próby oszustwa mogą bardzo dokładnie imitiować rzeczywiste wiadomości. Ważne, by dokładnie zweryfikować adres nadawcy.",
      title:
        "2023-04-28 POZNAN GL - WROCŁAW GL - bilet PKP Intercity nr 168039212",
      senderName: "bilet.eic@intercity.pl",
      sender: Address8,
      mail: MailContent8,
      correctAnswer: "non-phishing",
      explanationCorrect:
        "Dobrze! Ten mail faktycznie został wysłany przez intercity i jest bezpieczny. Ale pamiętaj - jeśli mail dotyczy usługi, przejazdu lub zamówienia, zawsze upewnij się, że powinieneś ten mail otrzymać. Po otworzeniu załączników i linków może być już zbyt późno.",
      explanationIncorrect:
        "Ten mail został wysłany przez intercity i jest bezpieczny. Ale pamiętaj - jeśli mail dotyczy usługi, przejazdu lub zamówienia, zawsze upewnij się, że powinieneś ten mail otrzymać. Po otworzeniu załączników i linków może być już zbyt późno.",
    },

    {
      question:
        "Koleżanka z innego działu wysłała mail z linkiem do pliku w dokumentach Google",
      description:
        "Pamiętaj, by sprawdzać adresy URL linków, najeżdżając na nie wskaźnikiem myszy lub przytrzymując je, a także sprawdzać adresy e-mail - czasami wyglądają one podobnie do prawdziwych, ale odrobinę się różnią.",
      title: "Budżet działu na nadchodzący rok",
      senderName: "Maria Konewka",
      sender: Address4,
      mail: MailContent4,
      correctAnswer: "phishing",
      explanationCorrect:
        "Dobrze! Pewnie widzisz, że adres URL wygląda prawidłowo, ale imituje inny adres. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
      explanationIncorrect:
        "Adres URL wygląda prawidłowo, ale imituje inny adres. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
    },
    {
      question: "Darmowy SMART od Allegro?",
      description:
        "Allegro wyszło z inicjatywą walki z kolejnymi zarażeniami Covid. Zachęcają do pozostania w domach i zamawiania przesyłek z wykorzystaniem darmowej usługi SMART.",
      title: "Jak aktywować Allegro Smart 23486244",
      senderName: "Allegro",
      sender: Address2,
      mail: MailContent2,
      correctAnswer: "phishing",
      explanationCorrect:
        "Dobrze! Twoje podejrzenia wzbudził pewnie podejrzany adres e-mail nadawcy. Powinien być to adres o domenie allegro.pl. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
      explanationIncorrect:
        "Zwróć uwagę na podejrzany adres e-mail nadawcy. Powinien być to adres o domenie allegro.pl. Zachowaj ostrożność przy otwieraniu hiperlinków i załączników zawartych w e-mailach. Mogą one przekierowywać do fałszywych stron, na których pojawiają się prośby o podanie informacji poufnych.",
    },
    {
      question: "Ostrzeżenie z Dropbox",
      description:
        "Co, jeśli chmura się zapełni? Powinieneś jak najszybciej uzyskać więcej przestrzeni!",
      title:
        "Nie daj się zaskoczyć i zadbaj o wystarczającą ilość miejsca w chmurze",
      senderName: "Dropbox",
      sender: Address9,
      mail: MailContent9,
      correctAnswer: "non-phishing",
      explanationCorrect:
        "Dobrze! To prawdziwy komunikat z usługi Dropbox. Nadawcą jest „dropboxmail.com” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „dropbox.com”.",
      explanationIncorrect:
        "To prawdziwy komunikat z usługi Dropbox. Nadawcą jest „dropboxmail.com” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „dropbox.com”.",
    },

    {
      question: "Paczka dotarła do paczkomatu",
      description: "Już pewnie nie możesz się doczekać, by ją rozpakować!",
      title: "Twój Paczkomat: Paczka już na Ciebie czeka",
      senderName: "InPost",
      sender: Address10,
      mail: MailContent10,
      correctAnswer: "non-phishing",
      explanationCorrect:
        "To prawdziwy komunikat on InPost. Nadawcą jest „paczkomaty.pl” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „inpost.pl” i „maps.google.com”.",
      explanationIncorrect:
        "To prawdziwy komunikat on InPost. Nadawcą jest „paczkomaty.pl” - wprawdzie jest to domena nietypowa, ale prawdziwa. Adres URL to bezpieczny link (https) prowadzący do „inpost.pl” i „maps.google.com”.",
    },

    {
      question: "Otrzymałes potwierdzenie przelewu",
      description:
        "Częstym motywem phishingowych wiadomości e-mail są też faktury lub płatności z nimi powiązane — oczywiście z niebezpiecznymi załącznikami lub linkami w treści",
      title: "FW: Potwierdzenie transakcji",
      senderName: "confirmation@ipko.pl",
      sender: Address3,
      mail: MailContent3,
      correctAnswer: "phishing",
      explanationCorrect:
        "Domena ipko.pl faktycznie istnieje i należy do banku. Jednakże, takie e-maile wyglądają zazwyczaj inaczej. Przypomnij sobie, czy wcześniej otrzymywałeś takie wiadomości od swojego banku. Jeśli tak, porównaj ich wiarygodność. \nTo była zaawansowana próba wyłudzenia informacji. Pliki PDF mogą zawierać złośliwe oprogramowanie lub wirusy. Zawsze sprawdzaj, czy otrzymujesz plik od zaufanego nadawcy, i korzystaj z przeglądarki lub usługi online takiej jak Dysk Google, by otwierać je bezpiecznie.",
      explanationIncorrect:
        "Domena ipko.pl faktycznie istnieje i należy do banku. Jednakże, takie e-maile wyglądają zazwyczaj inaczej. Przypomnij sobie, czy wcześniej otrzymywałeś takie wiadomości od swojego banku. Jeśli tak, porównaj ich wiarygodność. W tym mailu zdecydowanie czegoś brakuje.\nTo była zaawansowana próba wyłudzenia informacji. Pliki PDF mogą zawierać złośliwe oprogramowanie lub wirusy. Zawsze sprawdzaj, czy otrzymujesz plik od zaufanego nadawcy, i korzystaj z przeglądarki lub usługi online takiej jak Dysk Google, by otwierać je bezpiecznie.",
    },
    {
      question: "Ktoś z zarządu czeka, aż zweryfikujesz materiały.",
      description:
        "W przeszłości wielokrotnie otrzymywałeś podobne wiadomości.",
      title: "Materiały do weryfikacji",
      senderName: "anna@techcom-office.pl",
      sender: Address6,
      mail: MailContent6,
      correctAnswer: "phishing",
      explanationCorrect:
        "Wygląda na to, że udało Ci się zauważyć sfałszowany adres URL. Prawdziwą jego domeną jest „modelo.net”, ale został on zaprojektowany tak, by udawał, że pochodzi z Dysku Google. Zawsze sprawdzaj cały adres URL - nawet wtedy, gdy nadawca wydaje się znajomy.",
      explanationIncorrect:
        "Zwróć uwagę na sfałszowany adres URL. Prawdziwą jego domeną jest „modelo.net”, ale został on zaprojektowany tak, by udawał, że pochodzi z Dysku Google. Zawsze sprawdzaj cały adres URL - nawet wtedy, gdy nadawca wydaje się znajomy.",
    },
  ],
};
