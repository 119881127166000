import { HintContext } from "../../pages/QuizPage";

const Address7 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <p className="grayText">
          email@mail.onedrive.com
        </p>
      )}
    </HintContext.Consumer>
  );
};

export default Address7;
