import { HintContext } from "../../pages/QuizPage";

const Address4 = () => {
  return (
    <HintContext.Consumer>
      {(hint) => (
        <p className="grayText">
          mariankon300@gmail.com
        </p>
      )}
    </HintContext.Consumer>
  );
};

export default Address4;
