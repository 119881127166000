const Attachment = ({ format = "pdf", filename = "filename.pdf" }) => {
  return (
    <div class="attachment">
      <img src="./pdf.png"></img>
      <div class="attachmentFooter">
        <span>{format}</span>
        <span class="filename">{filename}</span>
        <div class="fold"></div>
      </div>
    </div>
  );
};
export default Attachment;
